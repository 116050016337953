<template>
  <div>
    <!--  we have many different types of rooms ... please click proper tab -->
    <!--<hr style="padding:0px; margin:2px;"> commented out 2021 08 29 -->
      <div v-for="(each,index) in mysummary" :key="index">
        <div v-if="each.title !==''" class="mylocaltitlestyle">
          {{each.title}}
        </div>
        <div class="mylocaldesstyle">
          {{each.des}}
          <div class="mylocalbrstyle">
            <br>
          </div>
        </div>
      </div>
    <!--<hr style="margin-top:0px">  commented out 2021 08 29 -->
  </div>
</template>

<script>
  export default {
    name: 'DisplayPageSummary',
    props: [ 'mysummary', ],
  }
</script>
<style scoped>

.mylocaltitlestyle {
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}

.mylocaldesstyle {
  font-size: 13px;
}

.mylocalbrstyle {
  height: 10px;
}

</style>
